<template>
  <div>
    <div class="order-table-wrapper" style="padding-top: 0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top: 30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <div class="e-tabs-wrapper">
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 0 }"
                  @click="switchTable(0)"
                >
                  今天
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 1 }"
                  @click="switchTable(1)"
                >
                  昨天
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 2 }"
                  @click="switchTable(2)"
                >
                  本月
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 3 }"
                  @click="switchTable(3)"
                >
                  上月
                </div>
              </div>
              <span class="v-form-label">门店</span>
              <el-select
                clearable
                size="small"
                v-model="departmentCode"
                placeholder="选择门店"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                :picker-options="pickerOptions"
                size="small"
                v-model="cxDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <el-row type="flex" justify="end">
                <el-button
                  size="small"
                  class="e-button"
                  style="margin-left: 28px"
                  type="primary"
                  @click="chaxun"
                >
                  查询
                </el-button>
              </el-row>
            </div>
          </div>
          <div v-if="isShow">
            <div class="box">
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">新增会员 {{orRes.newAddNum}}名</span>
                  <p class="item-h3"> {{orRes.newAddAmount}}  元 </p>
                  <p class="item-h2">
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">续卡会员  {{orRes.renewalCardNum}}名</span>
                  <p class="item-h3"> {{orRes.renewalCardAmount}} 元</p>
                  <p class="item-h2"></p>
                </div>
              </el-card>

              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">耗卡消费</span>
                  <p class="item-h3"> {{orRes.consumptionCardAmount}} 元</p>
                  <p class="item-h2"></p>
                </div>
              </el-card>
              <el-card class="box-card" style="margin-right: 0px">
                <div class="text-item">
                  <span class="item-h1">套餐包 {{orRes.packageNum}}笔</span>
                  <p class="item-h3">
                    已使用项目数  {{orRes.usedItemNum}}
                  </p>
                  <p class="item-h3">
                    剩余项目数  {{orRes.noUsedItemNum}}
                  </p>
                </div>
              </el-card>
            </div>
            <div class="zhu">
              <div class="zhu-item" >
                <div id="left" style="width: 100%; height: 288px">
                </div>
                <p style="text-align: center;margin-bottom: 18px">顾客分析</p>
              </div>
              <div class="zhu-item">
                <div id="zhong" style="width: 100%; height: 288px"></div>
                <p style="text-align: center;margin-bottom: 18px">手工项目数统计</p>
              </div>
              <div class="zhu-item">
                <div id="right" style="width: 100%; height: 288px"></div>
                <p style="text-align: center;margin-bottom: 18px">套餐统计</p>
              </div>
            </div>
            <div class="keliu">
              <div style="width: 100%; height: auto">
                <div id="kll" style="width: 100%; height: 288px">
                </div>
                <p style="text-align: center;margin-bottom: 18px">客流统计</p>
              </div>
            </div>
            <div class="keliu">
              <div style="width: 100%; height: auto">
                <div id="qian" style="width: 100%; height: 288px">
                </div>
                <p style="text-align: center;margin-bottom: 18px">消费统计</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabIndex: 0,
        storeList: [],
        cxDate: [],
        departmentCode: "",
        orRes: {},
        isShow: false,
        pickerOptions: {
          onPick: ({maxDate, minDate}) => {
            this.pickDate = minDate.getTime()
            if (maxDate) {
              this.pickDate = ''
            }
          },
          disabledDate: time => {
            let choiceDateTime = new Date(this.pickDate).getTime()
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const max = maxTime
            if (this.pickDate) {
              return time.getTime() < min || time.getTime() > max;
            }
          }
        },
        dateList:["09:00","10:00","11:00","12:00","13:00",
          "14:00","15:00","16:00","17:00","18:00","19:00",
          "20:00","21:00","22:00","23:00"
        ],
      };
    },
    async created() {
     await  this.getStoreList();
    },
    methods: {
      chaxun(){
        this.getOperationReport()
      },
      //顾客分析
      customer(){
        let myChart = this.$echarts.init(document.getElementById("left"));
         let option = {
          xAxis: {
            type: 'category',
            data: ['散客客次', '会员客次', '会员总客']
          },
          yAxis: {
            type: 'value'
          },
           tooltip: {
             trigger: 'axis',
             axisPointer: {            // 坐标轴指示器，坐标轴触发有效
               type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
             }
           },
          series: [{
            data: this.orRes.customerList,
            type: 'bar',
            showBackground: true,
            color: '#2878FF',
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            }
          }]
        };
       myChart.setOption(option);
      },
      //手工项目数统计
      item(){
        let myChart = this.$echarts.init(document.getElementById("zhong"));
         let option = {
          xAxis: {
            type: 'category',
            data: ['散客项目数', '会员项目数', '手工项目总数', ]
          },
          yAxis: {
            type: 'value'
          },
           tooltip: {
             trigger: 'axis',
             axisPointer: {            // 坐标轴指示器，坐标轴触发有效
               type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
             }
           },
          series: [{
            data: this.orRes.itemList,
            type: 'bar',
            showBackground: true,
            color: '#2878FF',
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            }
          }]
        };
       myChart.setOption(option);
      },

      //套餐统计
      package(){
        let myChart = this.$echarts.init(document.getElementById("right"));
         let option = {
          xAxis: {
            type: 'category',
            data: ['已使用项目数', '剩余项目数', '套餐数']
          },
          yAxis: {
            type: 'value'
          },
           tooltip: {
             trigger: 'axis',
             axisPointer: {            // 坐标轴指示器，坐标轴触发有效
               type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
             }
           },
          series: [{
            data: this.orRes.packageList,
            type: 'bar',
            showBackground: true,
            color: '#2878FF',
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            }
          }]
        };
       myChart.setOption(option);
      },
      //客流量统计
      keliuliang(){
        let myChart = this.$echarts.init(document.getElementById("kll"));
         let option = {
           tooltip: {
             trigger: 'axis'
           },
           color: '#2878FF',
           xAxis: {
             type: 'category',
             data: this.dateList,
           },
           yAxis: {
             type: 'value'
           },
           series: [{
             data: this.orRes.passengersFlowList,
             type: 'line',
             smooth: true
           }]
         };
        myChart.setOption(option);
      },

      //消费金额统计
      xiaofei(){
        let myChart = this.$echarts.init(document.getElementById("qian"));
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          color: '#2878FF',
          xAxis: {
            type: 'category',
            data: this.dateList,
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: this.orRes.consumptionList,
            type: 'line',
            smooth: true
          }]
        };
        myChart.setOption(option);
      },

      switchTable(index) {
        this.cxDate=[]
        this.tabIndex = index;
      },

      async getStoreList() {
        let res = await this.$get("/admin/getStoreList");
        this.storeList = res;
      },

      async getOperationReport() {
        if (!this.departmentCode) {
          this.$message.error("请选择门店!")
          return;
        }
        let staDate = "";
        let endDate = "";
        let dateType = "";
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0];
          endDate = this.cxDate[1];
          dateType = "";
        } else {
          dateType = this.tabIndex;
        }
        let data = {
          dateType: dateType,
          staDate: staDate,
          endDate: endDate,
          departmentCode: this.departmentCode,
        };
        let res = await this.$get("/admin/getOperationReport", data);
        if (res.code == 200) {
          this.orRes = res.orRes;
          this.isShow = true;
          this.$nextTick(function() {
            this.customer()
            this.item()
            this.package()
            this.keliuliang()
            this.xiaofei()
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
   .box{
     margin:auto;
     display:flex;
     flex-direction: row;
   }
  .box-card {
    width: 25%;
    margin-right: 20px;
    display: inline-block;
  }
  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      margin-left: 20px;
      span {
        margin-right: 5px;
      }
    }
  }

  .text-item1 {
    margin-top: 30px;
    width: 35%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .text-item1-p {
      font-size: 13px;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      margin-left: 20px;
      span {
        margin-right: 5px;
      }
    }
  }

  .passenger-right,
  .passenger-left {
    float: left;
    font-size: 12px;
  }

  .passenger-left {
    width: 30%;
  }

  .zhu{
    display:flex;
    flex-direction: row;
    height: auto;
    margin-top: 18px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFFFFF;
    overflow: hidden;
    color: #30312D;
    transition: 0.3s;
  }
  .zhu-item{
    margin: 0px 28px 0px 28px;
    width: 100%;
    height: auto
  }
   .keliu{
     height: auto;
     margin-top: 18px;
     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     border-radius: 4px;
     border: 1px solid #EBEEF5;
     background-color: #FFFFFF;
     overflow: hidden;
     color: #30312D;
     transition: 0.3s;
   }
</style>
