<template>
  <div>
    <div class="e-breadcrumb">运营中心</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">运营报表
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">员工提成
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==2}" @click="switchTable(2)">手工提成
              </div>
            </div>
          </div>
          <TabStatements  v-if="tabIndex==0"/>
          <TabCommission  v-if="tabIndex==1"/>
          <TabManual  v-if="tabIndex==2"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TabStatements from './statements'
  import TabCommission from './commission'
  import TabManual from './manual'

  export default {
    data() {
      return {
        tabIndex:0,
        item: {},
      }
    },
    async created() {
    },
    methods: {

      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },

    },
    components: {
      TabStatements,
      TabCommission,
      TabManual,
    }
  }

</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
