<template>
  <div>
    <div class="order-table-wrapper" style="padding-top: 0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top: 30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <div class="e-tabs-wrapper">
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 0 }"
                  @click="switchTable(0)"
                >
                  今天
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 1 }"
                  @click="switchTable(1)"
                >
                  昨天
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 2 }"
                  @click="switchTable(2)"
                >
                  本月
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 3 }"
                  @click="switchTable(3)"
                >
                  上月
                </div>
              </div>
              <span class="v-form-label">门店</span>
              <el-select
                clearable
                size="small"
                v-model="departmentCode"
                placeholder="选择门店"
                @change="getEmployeeList(departmentCode)"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="v-form-label">员工</span>
              <el-select
                clearable
                size="small"
                v-model="employeeId"
                placeholder="选择员工"
              >
                <el-option
                  v-for="item in employeeList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                :picker-options="pickerOptions"
                size="small"
                v-model="cxDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <el-row type="flex" justify="end">
                <el-button
                  size="small"
                  class="e-button"
                  style="margin-left: 28px"
                  type="primary"
                  @click="getAchievement"
                >
                  查询
                </el-button>
              </el-row>
            </div>
          </div>
          <div v-if="isShow">
            <div class="zhu">
              <div style="width: 100%; height: auto">
                <div id="zhong" style="width: 68%; height: 328px;margin: auto"></div>
                <p style="text-align: center;margin-bottom: 18px">员工业绩统计</p>
              </div>
            </div>
            <div class="zhu">
              <div style="width: 100%; height: auto">
                <p style="text-align: center;margin:8px 0px 8px 0px">充值类统计</p>
               <div class="card">
                 <el-card shadow="never" class="card-item">
                   <div slot="header" class="clearfix-card">
                     <span>开卡业绩</span>
                     <span>提成</span>
                   </div>
                   <div class="clearfix-card" >
                     <span>{{empRes.kkAchievement}}</span>
                     <span>{{empRes.kkCommission}}</span>
                   </div>
                 </el-card>
                 <el-card shadow="never" class="card-item">
                   <div slot="header" class="clearfix-card">
                     <span>续卡业绩</span>
                     <span>提成</span>
                   </div>
                   <div  class="clearfix-card">
                     <span>{{empRes.xkAchievement}}</span>
                     <span>{{empRes.xkCommission}}</span>
                   </div>
                 </el-card>
               </div>
              </div>
            </div>
            <div class="zhu">
              <div style="width: 100%; height: auto">
                <p style="text-align: center;margin:8px 0px 8px 0px">销售类统计</p>
                <div class="card">
                  <el-card shadow="never" class="card-item">
                    <div slot="header" class="clearfix">
                      <span>套餐数</span>
                      <span>业绩</span>
                      <span>提成</span>
                    </div>
                    <div class="clearfix">
                      <span>{{empRes.packageNum}}</span>
                      <span>{{empRes.packageAchievement}}</span>
                      <span>{{empRes.packageCommission}}</span>
                    </div>
                  </el-card>
                  <el-card shadow="never" class="card-item">
                    <div slot="header" class="clearfix">
                      <span>卖品数</span>
                      <span>业绩</span>
                      <span>提成</span>
                    </div>
                    <div  class="clearfix">
                      <span>{{empRes.skuNum}}</span>
                      <span>{{empRes.skuAchievement}}</span>
                      <span>{{empRes.skuCommission}}</span>
                    </div>
                  </el-card>
                  <el-card shadow="never" class="card-item">
                    <div slot="header" class="clearfix">
                      <span>项目(销售)</span>
                      <span>业绩</span>
                      <span>提成</span>
                    </div>
                    <div  class="clearfix">
                      <span></span>
                      <span>{{empRes.itemSaleAchievement}}</span>
                      <span>{{empRes.itemSaleCommission}}</span>
                    </div>
                  </el-card>
                </div>
              </div>
            </div>
          <div class="zhu">
            <div style="width: 100%; height: auto">
              <p style="text-align: center;margin:8px 0px 8px 0px">手工操作类统计</p>
              <div class="card">
                <el-card shadow="never" class="card-item">
                  <div slot="header" class="clearfix">
                    <span>指定项目数</span>
                    <span>业绩</span>
                    <span>提成</span>
                  </div>
                  <div class="clearfix">
                    <span>{{empRes.zdItemNum}}</span>
                    <span>{{empRes.zdItemAchievement}}</span>
                    <span>{{empRes.zdItemCommission}}</span>
                  </div>
                </el-card>
                <el-card shadow="never" class="card-item">
                  <div slot="header" class="clearfix">
                    <span>非指定项目数</span>
                    <span>业绩</span>
                    <span>提成</span>
                  </div>
                  <div  class="clearfix">
                    <span>{{empRes.fzdItemNum}}</span>
                    <span>{{empRes.fzdItemAchievement}}</span>
                    <span>{{empRes.fzdItemCommission}}</span>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
          <div class="zhu">
            <div style="width: 100%; height: auto">
              <p style="text-align: center;margin:8px 0px 8px 0px">提成合计</p>
              <span style="text-align: center;margin: auto;display: inline-block;width: 100%;margin-bottom: 18px;margin-top: 8px">{{empRes.totalCommission}}</span>
            </div>
          </div>
          </div>
          <div class="zhu">
            <div style="width: 100%; height: auto;">
              <p style="text-align: center;margin:8px 0px 8px 0px">销售阶梯总业绩提成</p>
              <div style="display: flex">
                <span style="text-align: center;margin: auto;display: inline-block;width: 100%;margin-bottom: 18px;margin-top: 8px">总业绩:{{empRes.totalAchievement}}</span>
                <span style="text-align: center;margin: auto;display: inline-block;width: 100%;margin-bottom: 18px;margin-top: 8px">提成:{{empRes.saleCommission}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabIndex: 0,
        storeList: [],
        departmentCode: "",
        employeeList: [],
        employeeId: "",
        cxDate: [],
        empRes: {},
        isShow: false,
        pickerOptions: {
          onPick: ({maxDate, minDate}) => {
            this.pickDate = minDate.getTime()
            if (maxDate) {
              this.pickDate = ''
            }
          },
          disabledDate: time => {
            let choiceDateTime = new Date(this.pickDate).getTime()
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const max = maxTime
            if (this.pickDate) {
              return time.getTime() < min || time.getTime() > max;
            }
          }
        },
      };
    },
    created() {
      this.getStoreList();
    },
    methods: {
      //手工项目数统计
      item(){
        let myChart = this.$echarts.init(document.getElementById("zhong"));
        let option = {
          xAxis: {
            type: 'category',
            data: [' 开卡业绩 ', ' 续卡业绩  ', ' 套餐业绩 ', ' 卖品业绩 ', ' 劳动业绩 ', ' 散客客次 ', ' 会员客次 ','项目销售']
          },
          yAxis: {
            type: 'value'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          series: [{
            data: this.empRes.achievementList,
            type: 'bar',
            showBackground: true,
            color: '#2878FF',
            axisLabel : {//坐标轴刻度标签的相关设置。
              interval:0,
              rotate:"45"
            },
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            }
          }]
        };
        myChart.setOption(option);
      },


      switchTable(index) {
        this.cxDate=[]
        this.tabIndex = index;
      },

      async getStoreList() {
        let res = await this.$get("/admin/getStoreList");
        this.storeList = res;
      },

      async getEmployeeList(departmentCode){
        let res = await this.$get("/admin/getEmployeeListByCode",{"departmentCode":departmentCode});
        this.employeeList = res.list;
      },

      async getAchievement() {
        if (!this.departmentCode) {
          this.$message.error("请选择门店!")
          return;
        }
        if (!this.employeeId) {
          this.$message.error("请选择员工!")
          return;
        }
        let staDate = "";
        let endDate = "";
        let dateType = "";
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0];
          endDate = this.cxDate[1];
          dateType = "";
        } else {
          dateType = this.tabIndex;
        }
        let data = {
          dateType: dateType,
          staDate: staDate,
          endDate: endDate,
          departmentCode: this.departmentCode,
          employeeId: this.employeeId,
        };
        let res = await this.$get("/admin/getEmployeeCommission", data);
        if (res.code == 200) {
          this.empRes = res.empRes;
          this.isShow = true;
          this.$nextTick(function() {
            this.item()
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .box{
    margin:auto;
    display:flex;
    flex-direction: row;
  }
  .box-card {
    width: 25%;
    margin-right: 20px;
    display: inline-block;
  }
  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      margin-left: 20px;
      span {
        margin-right: 5px;
      }
    }
  }

  .text-item1 {
    margin-top: 30px;
    width: 35%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .text-item1-p {
      font-size: 13px;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      margin-left: 20px;
      span {
        margin-right: 5px;
      }
    }
  }

  .passenger-right,
  .passenger-left {
    float: left;
    font-size: 12px;
  }

  .passenger-left {
    width: 30%;
  }

  .zhu{
    display:flex;
    flex-direction: row;
    height: auto;
    margin-top: 18px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFFFFF;
    overflow: hidden;
    color: #30312D;
    transition: 0.3s;
  }
  .card{
    margin: auto;
    width: 88%;
    display:flex;
    flex-direction: row;
  }
  .card-item{
    width: 100%;
    margin: 0px 8px 26px 8px;
  }
  .clearfix-card span{
    display: inline-block;
    width: 50%;
  }
  .clearfix span{
    display: inline-block;
    width: 33%;
  }
</style>
